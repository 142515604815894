<template>
    <div>
        <template v-if="selectedCV">
            <CVCard :CV="selectedCV">
                <div class="mr-2 cursor-pointer">
                    <img
                        src="../../../../../assets/icons/eye-icon.svg"
                        alt="eye-icon"
                    />
                </div>
                <div class="cursor-pointer">
                    <img
                        src="../../../../../assets/icons/copy-icon-black.svg"
                        alt="copy-icon-black"
                    />
                </div>
            </CVCard>
            <div class="pb-2 mb-2 bb-1 flex flex__item-center">
                <Checkbox
                    id="continue-with-cv"
                    class="mr-1"
                    v-model="continueWithCV"
                />
                <label for="continue-with-cv" class="cursor-pointer"
                    >Continue with CV</label
                >
            </div>
        </template>
        <p class="fs-24 mb-2 fw-600 text__dark-grey">Apply with new CV</p>
        <FileGrabber
            v-model="selectedCV"
            inside-text="Drop your CV here"
            :accept="['.jpg', '.doc', '.docx', '.pdf']"
            class="mb-2"
        />
        <div class="flex flex__spaced flex__col-gap-1">
            <Button @click="$emit('close')" theme="plain">Cancel</Button>
            <Button theme="blue">Apply</Button>
        </div>
    </div>
</template>

<script>
import Checkbox from "@/components/Checkbox";
import FileGrabber from "@/components/FileGrabber";
import Button from "@/components/Button";
import CVCard from "@/components/CVCard";

export default {
    name: "JobApply",
    components: {
        Checkbox,
        FileGrabber,
        Button,
        CVCard,
    },
    data() {
        return {
            continueWithCV: false,
            selectedCV: "",
        };
    },
};
</script>
