<template>
    <div>
        <div class="m-d-none">
            <Button
                @click="
                    loggedIn.active
                        ? $emit('showModal')
                        : $router.push({ name: 'login' })
                "
                class="mb-3 w-26"
                theme="green"
            >
                Apply for this position now
            </Button>
            <p class="fw-400 fs-14 text__dark-grey mb-1">or apply via Email</p>
            <p
                class="fw-600 fs-16 text__dark-grey mb-2 text__mid-green text-underline"
            >
                Careers@google.com
            </p>
            <div class="flex flex__wrap flex__spaced mb-5">
                <button class="socials__tab cursor-pointer">
                    <div class="mr-1 w-2">
                        <img
                            src="../../../../../assets/icons/share-plain-icon.svg"
                            alt="share-plain-icon"
                        />
                    </div>
                    <span class="fw-600 fs-16 text__black">Share</span>
                </button>
                <button class="socials__tab cursor-pointer">
                    <div class="mr-1 w-2">
                        <img
                            src="../../../../../assets/icons/twitter-plain-icon.svg"
                            alt="twitter-plain-icon"
                        />
                    </div>
                    <span class="fw-600 fs-16 text__black">Tweet</span>
                </button>
                <button class="socials__tab cursor-pointer">
                    <div class="mr-1 w-2">
                        <img
                            src="../../../../../assets/icons/save-plain-icon.svg"
                            alt="save-plain-icon"
                        />
                    </div>
                    <span class="fw-600 fs-16 text__black">Save</span>
                </button>
            </div>
        </div>

        <div>
            <p class="fs-24 fw-600 text__dark-grey mb-4">
                Jobs you might be interested in
            </p>
            <info-card v-for="(n, i) in 8" :key="i" class="mb-3">
                <div class="flex flex__column flex__spaced flex__row-gap-3">
                    <div class="flex flex__item-center">
                        <div class="mr-2 w-6">
                            <img
                                src="../../../../../assets/icons/atat-icon.svg"
                                alt="atat-icon"
                            />
                        </div>
                        <p class="fw-600 fs-16">Front-end Developer</p>
                    </div>
                    <div class="flex flex__spaced">
                        <div class="flex flex__item-center">
                            <div class="mr-1 flex">
                                <img
                                    src="../../../../../assets/icons/pin-icon.svg"
                                    alt="pin-icon"
                                />
                            </div>
                            <span class="fw-600 fs-14 text__grey"
                                >Abuja, Nigeria</span
                            >
                        </div>
                        <div class="flex flex__item-center">
                            <div class="mr-1 flex">
                                <img
                                    src="../../../../../assets/icons/dollar-grey-icon.svg"
                                    alt="dollar-grey-icon"
                                />
                            </div>
                            <span class="fw-600 fs-14 text__grey"
                                >2000 - 6000</span
                            >
                        </div>
                    </div>
                </div>
            </info-card>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import InfoCard from "@/components/InfoCard";
import { mapGetters } from "vuex";

export default {
    name: "JobAside",
    components: {
        Button,
        InfoCard,
    },
    computed: {
        ...mapGetters({
            loggedIn: "auth/getLoggedIn",
        }),
    },
};
</script>

<style scoped></style>
