<template>
    <div>
        <info-card>
            <div class="flex flex__spaced pb-5 u-relative">
                <div class="flex m-flex__column">
                    <div class="w-8 mr-4">
                        <img
                            style="width: 100%"
                            src="../../../../../assets/icons/google-icon.svg"
                            alt="google-icon.svg"
                        />
                    </div>
                    <div class="flex flex__column flex__spaced">
                        <p class="fs-32 m-fs-18 fw-600 text__dark-grey">
                            {{ profession }}
                        </p>
                        <div
                            class="flex m-flex__wrap m-flex__shrink-1 m-flex__row-gap-1 mr-2"
                        >
                            <div
                                class="flex flex__spaced flex__item-center mr-4"
                            >
                                <div class="flex mr-1" style="width: 15px">
                                    <img
                                        style="width: 100%"
                                        src="../../../../../assets/icons/pin-black-icon.svg"
                                        alt="pin-black"
                                    />
                                </div>
                                <span class="fs-16 text__grey">
                                    Abuja , Nigeria
                                </span>
                            </div>
                            <div
                                class="flex flex__spaced flex__item-center mr-4"
                            >
                                <div class="flex mr-1" style="width: 15px">
                                    <img
                                        style="width: 100%"
                                        src="../../../../../assets/icons/clock-black-icon.svg"
                                        alt="clock-black"
                                    />
                                </div>
                                <span class="fs-16 text__grey"
                                    >2 Hours Ago</span
                                >
                            </div>
                            <div
                                class="flex flex__spaced flex__item-center mr-4"
                            >
                                <div class="flex mr-1" style="width: 15px">
                                    <img
                                        style="width: 100%"
                                        src="../../../../../assets/icons/dollar-icon.svg"
                                        alt="dollar-icon"
                                    />
                                </div>
                                <span class="fs-16 text__grey"
                                    >2000 - 6000</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job__type">
                    <Tag text="Part Time" background="blue" />
                </div>
            </div>

            <div class="d-none m-d-block">
                <div class="flex flex__spaced py-2 by-1">
                    <span class="mr-5 text__grey fw-400"
                        >Opening: <span class="text-blue">12</span></span
                    >
                    <span class="text__grey fw-400">
                        Job Applicants: <span class="text-blue">1258</span>
                    </span>
                </div>

                <Button
                    @click="
                        loggedIn.active
                            ? $emit('showModal')
                            : $router.push({ name: 'login' })
                    "
                    class="mb-3 mt-2"
                    theme="green"
                >
                    Apply for this position now
                </Button>
                <div class="flex flex__spaced flex__item-center px-2 mb-2">
                    <p class="fw-400 fs-14 text__dark-grey">
                        or apply via Email
                    </p>
                    <p
                        class="fw-600 fs-16 text__dark-grey text__mid-green text-underline"
                    >
                        Careers@google.com
                    </p>
                </div>
                <div class="flex flex__wrap flex__spaced mb-2">
                    <button class="socials__tab cursor-pointer">
                        <div class="mr-1 w-2">
                            <img
                                src="../../../../../assets/icons/share-plain-icon.svg"
                                alt="share-plain-icon"
                            />
                        </div>
                        <span class="fw-600 fs-14 text__black">Share</span>
                    </button>
                    <button class="socials__tab cursor-pointer">
                        <div class="mr-1 w-2">
                            <img
                                src="../../../../../assets/icons/twitter-plain-icon.svg"
                                alt="twitter-plain-icon"
                            />
                        </div>
                        <span class="fw-600 fs-14 text__black">Tweet</span>
                    </button>
                    <button class="socials__tab cursor-pointer">
                        <div class="mr-1 w-2">
                            <img
                                src="../../../../../assets/icons/save-plain-icon.svg"
                                alt="save-plain-icon"
                            />
                        </div>
                        <span class="fw-600 fs-14 text__black">Save</span>
                    </button>
                </div>
                <div class="text__grey d-none m-d-block">
                    Posted 20 days ago
                </div>
            </div>

            <div class="bt-1 bb-1 py-3 flex flex__spaced m-d-none">
                <div>
                    <span class="mr-5 text__grey fw-400"
                        >Opening: <span class="text-blue">12</span></span
                    >
                    <span class="text__grey fw-400">
                        Job Applicants: <span class="text-blue">1258</span>
                    </span>
                </div>
                <div class="text__grey m-d-none">Posted 20 days ago</div>
            </div>

            <section class="py-3 bb-1">
                <h1 class="text__dark-grey fs-24 mb-3">Description</h1>
                <div class="mb-3">
                    <p class="text__dark-grey fw-600 fs-18 mb-1">
                        The roles and responsibilities for
                        {{ $route.params.profession }}:
                    </p>
                    <ul
                        style="list-style-type: disc"
                        class="fs-16 fw-400 section__content pl-2 lh-40 m-lh-32"
                    >
                        <li>
                            Review, analyse, evaluate and prioritize business
                            requirements
                        </li>
                        <li>
                            Document requirements, define scope, create
                            impactful presentations as well as insightful
                            spreadsheets
                        </li>
                        <li>
                            Design workflow charts/diagrams, evaluate system
                            capabilities, write specifications
                        </li>
                        <li>
                            End to end project management: Define project tasks,
                            actively track project progress, and milestones,
                            publish periodic progress reports, recommend actions
                        </li>
                        <li>
                            Prepare technical reports by collecting, analysing
                            and summarizing information
                        </li>
                        <li>Conduct cost/benefit analysis</li>
                        <li>
                            Act as a Finance process SME, and suggest process
                            improvements based on experience in applying
                            industry best practices at scale
                        </li>
                    </ul>
                </div>
                <div>
                    <p class="text__dark-grey fw-600 fs-18 mb-1">
                        Should have knowledge of:
                    </p>
                    <ul
                        style="list-style-type: disc"
                        class="fs-16 fw-400 section__content pl-2 lh-40 m-lh-32"
                    >
                        <li>Knowledge of Agile development methodologies</li>
                        <li>
                            Knowledge of Finance processes and financial
                            enterprise systems
                        </li>
                        <li>Well versed with tools such as JIRA</li>
                        <li>
                            Excellent written and verbal communication,
                            including technical writing skills
                        </li>
                        <li>
                            Mastery in documentation using MS Office suite
                            (Excel/PPT/Word)
                        </li>
                        <li>Business case development</li>
                        <li>Data analysis, reporting</li>
                        <li>Stakeholder management</li>
                    </ul>
                </div>
            </section>

            <section class="py-3 bb-1">
                <h1 class="text__dark-grey fs-24 mb-3">Education</h1>
                <p class="fs-18 mb-2">
                    UD: <span class="text__grey"> B.Tech </span>
                </p>
                <p class="fs-18">
                    PG:
                    <span class="text__grey">
                        Post Graduation Not Required
                    </span>
                </p>
            </section>

            <section class="py-3 bb-1">
                <h1 class="text__dark-grey fs-24 mb-3">Requirement</h1>
                <div class="section__content">
                    <p class="fs-16 lh-40 m-lh-32 mb-2">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam.
                    </p>

                    <div>
                        <div class="flex flex__item-center">
                            <div class="mr-2" style="width: 25px">
                                <img
                                    style="width: 100%"
                                    src="../../../../../assets/icons/check-blue-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                            <p
                                style="width: calc(100% - 25px)"
                                class="lh-40 m-flex__grow m-flex__shrink-1 m-lh-32"
                            >
                                Laboris laboris nostrud consect etur magna.
                            </p>
                        </div>
                        <div class="flex flex__item-center">
                            <div class="mr-2" style="width: 25px">
                                <img
                                    style="width: 100px"
                                    src="../../../../../assets/icons/check-blue-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                            <p
                                style="width: calc(100% - 25px)"
                                class="lh-40 m-flex__grow m-flex__shrink-1 m-lh-32"
                            >
                                Laboris laboris nostrud consect etur magna.
                                Aliqua sint dolore esse ut occaecat do.
                            </p>
                        </div>
                        <div class="flex flex__item-center">
                            <div class="mr-2" style="width: 25px">
                                <img
                                    style="width: 100%"
                                    src="../../../../../assets/icons/check-blue-icon.svg"
                                    alt="check-blue-icon"
                                />
                            </div>
                            <p
                                style="width: calc(100% - 25px)"
                                class="lh-40 m-flex__grow m-flex__shrink-1 m-lh-32"
                            >
                                Laboris laboris nostrud consect etur magna.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="py-3 bb-1">
                <h1 class="text__dark-grey fs-24 mb-3">Skills</h1>
                <div class="section__content flex flex__wrap flex__gap-2">
                    <span class="skills__tab--round">C</span>
                    <span class="skills__tab--round">C++</span>
                    <span class="skills__tab--round">JAVA</span>
                    <span class="skills__tab--round">SQL</span>
                    <span class="skills__tab--round">Windows server-2008</span>
                </div>
            </section>

            <section class="py-3 bb-1">
                <h1 class="text__dark-grey fs-24 mb-3">About Company</h1>
                <div class="section__content">
                    <p class="lh-40 m-lh-32">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                </div>
            </section>

            <section class="pt-3">
                <h1 class="text__dark-grey fs-24 mb-3">Perks and Benefits</h1>
                <div
                    class="flex flex__wrap flex__col-gap-8 flex__row-gap-3 section__content"
                >
                    <div class="flex flex__item-center">
                        <div class="mr-2">
                            <img
                                style="width: 100%"
                                src="../../../../../assets/icons/check-green-icon.svg"
                                alt="check-green-icon"
                            />
                        </div>
                        <span>Lunch</span>
                    </div>
                    <div class="flex flex__item-center">
                        <div class="mr-2">
                            <img
                                style="width: 100%"
                                src="../../../../../assets/icons/check-green-icon.svg"
                                alt="check-green-icon"
                            />
                        </div>
                        <span>Healthcare</span>
                    </div>
                    <div class="flex flex__item-center">
                        <div class="mr-2">
                            <img
                                style="width: 100%"
                                src="../../../../../assets/icons/check-green-icon.svg"
                                alt="check-green-icon"
                            />
                        </div>
                        <span>Vacation/Paid Time Off</span>
                    </div>
                    <div class="flex flex__item-center">
                        <div class="mr-2">
                            <img
                                style="width: 100%"
                                src="../../../../../assets/icons/check-green-icon.svg"
                                alt="check-green-icon"
                            />
                        </div>
                        <span>Gym Membership</span>
                    </div>
                </div>
            </section>
        </info-card>
    </div>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import Tag from "@/components/Tag";
import { mapGetters } from "vuex";

export default {
    name: "JobView",
    props: ["profession"],
    components: {
        InfoCard,
        Tag,
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        ...mapGetters({
            loggedIn: "auth/getLoggedIn",
        }),
    },
};
</script>

<style lang="scss" scoped>
@use "../../../../../assets/scss/mixins/index" as *;

.section {
    &__content {
        max-width: 75%;

        @include query("mobile") {
            max-width: 100%;
        }

        & li::marker {
            color: #505565;
        }
    }
}

.job__type {
    @include query("mobile") {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
