<template>
    <div>
        <PageLayout :headerWithBG="true">
            <div class="job__container">
                <p class="job__page-title mb-3 m-d-none">
                    <span>HOME / </span>
                    <span class="text__light-blue">{{ profession }} JOBS</span>
                </p>

                <div class="grid grid__layout gap-8 grid__mobile m-gap-0">
                    <div class="col-8 m-col-12">
                        <JobView
                            @showModal="toggleModal"
                            :profession="profession"
                        />
                    </div>
                    <div class="col-4 m-col-12 pt-2">
                        <JobAside @showModal="toggleModal" />
                    </div>
                </div>
            </div>
        </PageLayout>
        <Modal
            :fullMobileWidth="true"
            :active="showModal"
            :showCloseIcon="false"
        >
            <JobApply @close="showModal = !showModal" />
        </Modal>
    </div>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import JobView from "./components/JobView";
import JobAside from "./components/Aside";
import Modal from "@/components/Modal";
import JobApply from "./components/JobApply";

export default {
    name: "Job",
    props: ["profession"],
    components: {
        PageLayout,
        JobView,
        JobAside,
        JobApply,
        Modal,
    },
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        toggleModal() {
            this.showModal = !this.showModal;
        },
    },
};
</script>

<style scoped lang="scss">
@import "./job.scss";
</style>
